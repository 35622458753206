@import "./mixins"; 
.auth-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    // background: linear-gradient(0deg, #FFFFFF 0%, #FFF4E0 100%);
    min-height: calc(100vh);
    // background: url(../../assets/login-bk.png);
    // background-repeat: no-repeat;
    // background-size: cover;
    // overflow: auto;
    .auth-bg{
      position: absolute;
      bottom: 0; 
      z-index: 1;
    }
    .auth-form-content { 
      width: 550px;
      height: auto;
      margin: 60px 0;
      padding: 31px 85px 60px 85px;
      background-color: #ffffff;
      border: 1px solid #FFFFFF;
      border-radius: 8px;
      box-shadow: 0 4px 8px 0 rgba(15,40,55,0.1);
      display: flex;
      align-items: center;
      justify-content: center; 
      flex-direction: column;
      position: relative;
      z-index: 5;
      > form {
        width: 100%;
        .ant-btn.ant-btn-lg{
          box-shadow: 0 4px 8px 0 rgba(147,149,152,0.2);
        }
      }
      .form-welcom {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.5;
        color: #0080b1;
      }
      .login-text {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.6;
        color: #000000;
        margin-top: 7px;
        margin-bottom: 10px;
        text-align: center; 
      }
      .user-text {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        margin-bottom: 30px;
        a{
          margin: 0 4px;
        }
      }
      .logo-holder{
        margin-bottom: 20px;
        @include mq("tablet"){
          margin-bottom: 20px;
        }
      }
      @include mq("tablet"){
        width: 90%;
        height: auto;
        padding: 30px 40px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      }
    }
    .forget-link{
      display: block;
      color: #CC3524;
      font-size: 16px;
      font-weight: bold;
      line-height: 26px;
      text-align: center;
      margin-top: 26px; 
    }
  }

  .facebook-login-holder{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 26px; 
    p{
      font-size: 14px;
      font-weight: normal;
      color: #000000;
      margin-right : 10px;
    }
  }
  
  .back-btn{
    color: #939598;
    font-size: 14px;
    line-height: 24px; 
    width: 100%;
    display : flex;
    align-items: center;
    margin-bottom : 10px;
    position : relative;
    right: -50px;
    svg{
      color: #C2A062;
      margin-left : 4px;
    }
    @include mq("tablet"){
      right: 0;
    }
  }
  .form-red-p{
    color: #CC3524;
    font-size: 20px;
    font-weight: bold;
    line-height: 32px;
    margin-top : 15px;
  }
  .form-hint-p{
    color: #2F343A;
    font-size: 14px;
    line-height: 24px;
    text-align: center; 
    margin-bottom : 40px;
    margin-top : 15px;
  }

  .form-mail-s{
    color: #2F343A;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 30px;
  }
.otp-form-holder{
  >div{
    &:first-of-type{
      margin-bottom: 15px;
      justify-content: center;
    }
    // flex-direction: row-reverse;
    .input-otp{
      width: 82px;
      @include mq("tablet"){
        width: 50px;
      }
    }
  }
  .resend-link-holder{
    margin-left: auto;
    margin-bottom: 20px;
  }
}
.resend-verify{
  text-align: right;
}
  .input-otp{
    input{
        width: 62px !important;
        height: 58px;
        font-size: 30px; 
        border-radius: 8px;
        border: 1px solid #93959880;
        @include mq("tablet"){
          width: 44px !important;
          height: 44px;
        }
    }
    span{
        margin: 0 10px;
    }
}
.resend-verif{ 
  text-align: left;
  margin: 0 20px;
  margin-bottom: 35px;
  color: #939598;
  font-size: 14px;
}
.form-hint-h{
  color: #2F343A;
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
}
