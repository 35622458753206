@import "../../styles/mixins";

.order__status--tag {
  min-width: 82px;
  height: 26px;
  @include flexCenter;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  background-color: #612166;
  margin: auto;

  &.pending, &.pendingcompanyapproval {
    background-color: #fcb852;
    
  }

  &.delivered, &.shipmentdelivered {
    background-color: #14dc94;
    
  }

  &.canceled {
    background-color: #fc4242;
    pointer-events: none;
  }
  &.preparing {
    background-color: #fff;
    color: #fff;
  }

  &.iscancelled, &.companyrejected, &.canceledbycustomer, &.canceledbydelivery {
    background-color: #fc4242;
    color: #fff;
  }
}

.order__actions {
  display: flex;
  justify-content: center;
  width: 100px;
  margin: auto;

  span {
    font-size: 12px;
    font-weight: 600;
    color: #612166;
    text-decoration: underline;
    cursor: pointer;
    &.canceled { 
      pointer-events: none; 
    }
    &:last-child {
      color: #fc4242;
    }
  }
}
.dropdown-wrapper{
  min-width: 200px !important;
  width: 200px !important;
  .ant-select-item-option{
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 17px;
    color: #000;
  }
}
.order-status-select {
  .ant-select-selection-item {
    font-weight: bold;
  }
}

.order__wrapper {
  .table__wrapper .ant-table .ant-table-tbody tr td {
    font-weight: bold;
  }
}


.filter__by--order {
  .ant-select-selector {
    width: 115px !important;
  }
}