/* Custom CSS for Quill videos */
.ql-editor .ql-video {
  width: 100%; /* or any specific width like '600px' */
  height: 600px; /* or any specific height */
  max-width: 100%; /* Ensure the video doesn't overflow its container */
}
.ql-snow .ql-tooltip {
  right: 0 !important;
  left: 60vw !important;
}
.btn:hover {
  background-color: #612166;
}
