@import "../../../styles/mixins";

.personal__info {
  border-radius: 10px;
  border: solid 1px #e5e5e5;
  background-color: #fff;
  padding: 20px 90px 30px;
  direction: rtl;

  > p {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    margin-bottom: 20px;
  }
}

.form__wrapper {
  .ant-form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    .ant-form-item-label {
      margin-bottom: 17px;

      > label {
        font-size: 14px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        direction: ltr;

        &::after {
          display: none;
        }

        &::before {
          color: #fff;
        }
      }
    }

    .ant-form-item-control {
      .ant-input,
      .ant-input-password,
      .ant-select-selector {
        width: 100%;
        max-width: 439px;
        height: 60px;
        border-radius: 10px;
        border: solid 1px #dbdbdb;
        background-color: #fff;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        direction: ltr;

        .ant-select-selection-item {
          display: flex;
          align-items: center;
        }
      }

      .ant-input-password {
        .ant-input {
          width: 100%;
          border: none;
          background-color: #fff;
          border-radius: 0;
          height: auto;
        }
      }
    }
    &.ant-form-item-has-error {
      .ant-form-item-control {
        .ant-input,
        .ant-input-password {
          border-color: #ff4d4f !important;
        }

        .ant-input-password {
          .ant-input {
            border: none;
          }
        }
      }
    }
  }

  button {
    width: 128px;
    height: 50px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #612166;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    margin-top: 15px;

    @include mq("ipad") {
      width: 101px;
      height: 38px;
      font-size: 12px;
    }

    span {
      display: inline-block;
      margin-right: 14px;
    }

    &.btn__profile {
      color: #fff;
      background-color: #612166;
      border: 1px solid transparent;
    }
  }
}

