@import "../../styles/mixins";

.table__wrapper {
  padding: 33px 0 27px;
  border: solid 1px rgba(175, 185, 188, 0.25);
  background-color: #fff;
  border-radius: 12px;

  .table__wrapper--header {
    display: flex;
    padding: 0 30px 30px;

    > p,
    .table__title {
      font-size: 16px;
      font-weight: bold;
      color: #292d32;
      margin-right: 30px;

      span {
        display: inline-block;
        margin-left: 18px;
        font-weight: 600;
      }
    }
  }

  .table__container {
    width: 100%;
    overflow: auto;

    &::-webkit-scrollbar-thumb {
      background: #fff;
    }
  }

  .ant-table {
    width: 100%;

    .ant-table-thead {
      th {
        background-color: #e8faff;
        height: 84px;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.6px;
        color: #292d32;
        border: none;
        text-align: center;

        &::before {
          display: none;
        }

        .table-header-text {
          font-size: 12px;
          font-weight: bold;
          color: #292d32;
          text-transform: uppercase;

          span {
            display: block;
            font-weight: 600;
          }
        }
      }
    }

    .ant-table-tbody {
      tr {
        td {
          height: 66px;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0.6px;
          color: #000;
          text-align: center;

          img {
            width: 100%;
            height: 50px;
          }

          .ant-select-selector {
            width: 127px;
            height: 42px;
            border-radius: 10px;
            border: solid 1px #dbdbdb;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 500;
            color: #000;
          }
        }

        &:nth-child(odd) {
          td {
            background-color: #f1f1f1;
          }
        }

        &:nth-child(even) {
          td {
            background-color: #f8f8f8;
          }
        }

        &.ant-table-expanded-row {
          td {
            height: 54px;
            font-size: 14px;
            color: #000;
            padding-left: 22px;
            font-weight: 600;
            text-align: left;
            background-color: #dbdbdb;

            p {
              span {
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }

  .table-tag {
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    padding: 6px 12px;
    border-radius: 10px;
    border: none;
  }
}

.ant-pagination {
  padding: 0 20px;
}

.table__filter {
  @include mq("ipad") {
    flex-direction: column;

    > * {
      margin-bottom: 8px;
    }
  }
}

.ant-pagination {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;

  .ant-pagination-prev,
  .ant-pagination-next {
    border-radius: 5px;
    border: solid 1px #e8e8e8;
    background-color: #fff;
    font-size: 14px;
    font-weight: 600;
    color: #6f7172;
    margin: 0;
    border-radius: 5px 0 0 5px;
    height: 37px;
    padding: 0 9px;

    a {
      color: #6f7172;
    }
  }

  .ant-pagination-next {
    border-radius: 0 5px 5px 0;
  }

  .ant-pagination-prev {
    border-radius: 5px 0 0 5px;
  }

  .ant-pagination-item {
    height: 37px;
    width: 42px;
    margin: 0;
    border: none;
    background-color: #f8f8f8;
    line-height: 37px;

    a {
      color: #292d32;
    }

    &.ant-pagination-item-active {
      background-color: #612166;

      a {
        color: #fff;
      }
    }
  }
}
.delivery-col-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-avatar {
    margin-left: 5px;
  }
}

.ant-modal-footer .ant-btn.ant-btn-primary {
  border-radius: 10px;
  background-color: #612166;
  color: #fff;
}
