@import "../../../styles/mixins";

.ant-layout {
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .ant-menu {
      height: 75vh;
      overflow-y: auto;
    }
  }

  &.ant-layout-has-sider {
    height: 100vh;

    .ant-layout-sider {
      background-image: radial-gradient(circle at 85% 4%, #fff, #612166 99%);
      flex: unset !important;
      max-width: unset !important;
      padding: 40px 0;

      &.open {
        width: 327px !important;
      }

      &.collapsed {
        width: 40px;
        padding: 0;
      }

      .ant-menu {
        background: transparent;

        .ant-menu-item {
          padding: 0 65px 0 40px !important;
          height: 59px;
          border-left: 7px solid transparent;

          img {
            display: inline-block;
            margin-right: 15px;
          }

          svg {
            display: inline-block;
            font-size: 22px;
            margin-right: 15px;
            color: #fff;
          }

          &.ant-menu-item-selected {
            background-color: #612166;
            border-left-color: #612166;
          }
        }

        .ant-menu-title-content {
          p {
            @include flexBetween;
            font-size: 12px;
            font-weight: 600;
            color: #fff;
          }
        }

        .ant-menu-submenu {
          padding: 0 65px 0 40px;
          border-left: 7px solid transparent;
          height: 59px !important;
          overflow: hidden;

          &.ant-menu-submenu-active {
            height: 59px !important;
            overflow: hidden;
          }

          img {
            display: inline-block;
            margin-right: 15px;
          }

          svg {
            display: inline-block;
            font-size: 22px;
            margin-right: 15px;
          }

          .ant-menu {
            height: auto;
            overflow-y: hidden;

            .ant-menu-item {
              padding: 0 0 0 15px !important;

              &.ant-menu-item-selected {
                background-color: #612166;
                border-left-color: transparent;
                position: relative;
                overflow-y: visible;

                &::before {
                  content: "";
                  background-color: #612166;
                  left: 1px;
                  border-radius: 50%;
                  width: 9px;
                  height: 9px;
                  position: absolute;
                }
              }
            }
          }

          .ant-menu-submenu-title {
            padding: 0 !important;
            min-height: 59px;
            overflow: unset;

            // .ant-menu-submenu-arrow {
            //   height: 100%;
            //   display: flex;
            //   align-items: center;
            // }
          }

          &.ant-menu-submenu-open {
            background-color: #612166;
            border-left-color: #612166;
            height: auto !important;
            overflow: unset;

            &.ant-menu-submenu-active {
              height: auto !important;
              overflow: unset;
            }
          }
        }
      }

      .logo__wrapper {
        @include flexBetween;
        justify-content: center;
        color: #fff;
        padding: 0 40px;
        position: relative;

        .collapse-icon {
          font-size: 22px;
          position: absolute;
          right: -43px;
          color: #612166;
          top: -12px;
          cursor: pointer;
          &:lang(ar) {
            right: auto;
            left: -43px;
          }
        }
      }
    }
  }

  .ant-layout-sider {
    &.ant-layout-sider-collapsed {
      &.collapsed {
        padding-top: 40px;

        .logo__wrapper {
          img {
            width: 45px;
          }
        }

        .ant-menu {
          .ant-menu-item {
            padding: 0 11px 0 19px !important;
            line-height: 57px;
          }

          .ant-menu-submenu {
            padding: 0 5px 0 21px !important;

            .ant-menu-submenu-title {
              line-height: 57px;
            }
          }
        }
      }
    }
  }
}

.ant-menu-submenu-arrow {
  opacity: 1 !important;
  right: -25px;
}
.ant-tooltip-inner p,
.ant-menu-title-content p {
  color: #fff;
}
