.product__wrapper {
  border-radius: 12px;
  overflow: hidden;

  .product__wrapper--header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #612166;

    p {
      &:first-child {
        margin-right: 20px;
      }
    }
  }

  .table__wrapper {
    border-radius: 0;
  }
}

.table__filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .table__filter--count {
    display: flex;
    align-items: center;

    p {
      font-size: 14px;
      font-weight: normal;
      color: #292d32;
      margin: 0 18px 0 0;
    }

    span {
      margin: 0 20px 0 7px;
      font-size: 14px;
      font-weight: normal;
      color: #292d32;
      display: inline-block;
    }
    .ant-select {
      .ant-select-selector {
        display: flex;
        align-items: center;
        height: 40px;
        width: 64px;
        border-radius: 6px;
        border: solid 1px #dbdbdb;

        .ant-select-selection-item {
          margin: 0 7px 0 0;
          padding: 0;
        }
      }
      .ant-select-arrow {
        right: 0;
        font-size: 10px;
        top: 14px;
      }
    }
  }

  .product__btn {
    .secondary {
      margin-right: 10px;
    }
  }
}
.CouponDrawer {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  align-items: space-between;
}

.CouponDrawer-Mail-btn {
  margin-top: 20px;
}
.Drawer-coupondetails {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  span {
    display: flex;
  }
  p {
    font-weight: bold;
  }
}
.ql-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.ql-editor {
  flex-grow: 1;
  overflow-y: auto;
}
