// @import "../utilities";

.notification_page {
  .notification_page_holder {
    padding: 7rem 0;
    .head_title {
      font-size: 4rem;
      font-weight: 600;
      letter-spacing: 0.32px;
      // color: $color-secondary;
      text-transform: capitalize;
      margin-bottom: 4rem;
    }
    .notification_list {
      padding: 0;
      .notification_item {
        display: flex;
        align-items: flex-start;
        gap: 3.4rem;
        padding: 3rem 4rem;
        border-radius: 26px;
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.05);
        background-color: #fff;
        margin-bottom: 3rem;

        img {
          min-width: 40px;
          height: 40px;
          padding: 0.8rem;
          background-color: #612166;
          border-radius: 50%;
        }
        .item_content {
          h4 {
            font-size: 22px;
            font-weight: 400;
            letter-spacing: normal;
            // color: $color-black-3;
            text-transform: capitalize;
            margin-bottom: 1rem;
          }
          p {
            font-size: 17px;
            // color: $color-gray-2;
          }
        }

        // @include mq("md") {
        //   gap: 2rem;
        //   padding: 3rem 2rem;

        //   img {
        //     min-width: 4rem !important;
        //   }
        //   .item_content {
        //     h4 {
        //       font-size: 3rem;
        //       margin-bottom: 1rem;
        //     }
        //     p {
        //       font-size: 2rem;
        //     }
        //   }
        // }
      }
    }
  }
}
