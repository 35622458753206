@import "./styles/mixins";
@import "./styles/auth.scss";
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body,
html {
  font-family: "Cairo", sans-serif;
  &:lang(ar) {
    direction: rtl;
  }
}

p {
  margin: 0;
}
* {
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    // background: #612166;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #fff;
  }
}

.content__wrapper {
  border: solid 1px rgba(175, 185, 188, 0.25);
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;

  .content__header {
    height: 66px;
    background-color: #612166;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-size: 16px;
      font-weight: 600;
      color: #fff;
    }
  }
}

.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  padding: 0 14px;
  border-radius: 10px;
  background-color: #612166;
  border: solid 1px #612166;
  font-size: 14px;
  font-weight: 600;

  &.ant-btn-primary {
    background-color: #612166;
    color: #fff;
    border-color: #612166;
  }

  &.primary {
    background-color: #612166;
    color: #fff;
  }

  &.secondary {
    color: #fff;
  }

  &.default {
    background-color: #612166;
    color: #fff;
    border-color: #612166;
  }
}

.ant-layout-content {
  height: calc(100vh - 90px);
  overflow: auto;

  &::-webkit-scrollbar-thumb {
    background: #fff;
  }
}

.ant-select {
  max-width: 439px;
}

.inter {
  font-family: "Inter", sans-serif !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.search__list--input {
  .ant-input {
    max-width: 220px;
    height: 45px;
    flex-grow: 0;
    padding: 0 20px;
    border-radius: 10px;
    border: solid 1px #dbdbdb;
    background-color: #fff;
  }
}

.download__list {
  border: solid 1px #e8e8e8;
  background-color: #f8f8f8;
  border-radius: 4px;
  display: flex;

  > a {
    width: 63px;
    height: 35px;
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    font-family: "Inter", sans-serif !important;
    border-right: solid 1px #e8e8e8;
  }

  &:last-child {
    border-right: none;
  }
}

.ltr-direction {
  direction: ltr !important;
}

.rtl-direction {
  direction: rtl !important;
}

.bold {
  font-weight: bold !important;
}

.normal-font {
  font-weight: 500 !important;
}

.table__hidden {
  display: none;
}

.dropdown__column--visible {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 20px 40px 0 rgba(175, 185, 188, 0.25);
  border-radius: 6px;
  padding: 10px;

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }
}

.selector-more-width {
  .ant-select-selector {
    width: 170px !important;
  }
}

.primary-text {
  color: #fff;
}

.ant-select {
  &.ant-select-multiple {
    .ant-select-selector {
      height: auto !important;
      padding: 10px !important;
    }
  }
}

.label-rtl {
  .ant-form-item-label {
    label {
      direction: rtl !important;
    }
  }
}

.modal-order-holder {
  .ant-form-item {
    display: flex;
    flex-direction: column;
    .ant-form-item-label {
      text-align: left;
    }
  }
}
.delivery-col-holder.delivery-col-modal {
  // height: 30px;
  justify-content: flex-start;
}

.PhoneInputCountry {
  background: #fff;
  padding-left: 8px;
  padding-right: 16px;
  margin-left: 0;
  // &:lang(ar) {
  //   padding-right: 16px;
  //   padding-left: 8px;
  // }
  border-radius: 16px;
}

.PhoneInputInput {
  border: 0;
  padding: 0;
  height: 100%;
}
.PhoneInputCountrySelect {
  border-radius: 16px;
}
.PhoneInputCountrySelectArrow {
  color: #000;
  opacity: 1;
  font-size: 21px;
  margin-right: 20px;
  // &:lang(ar) {
  //   margin-left: 0;
  //   margin-right: 6px;
  // }
}

.PhoneInput {
  margin-bottom: 0.5rem;
  height: 7.5rem;
  border: 0.5px solid #dbdbdb;
  border-radius: 12px;
  max-width: 439px;
  flex-direction: row-reverse;
  .ant-input-prefix {
    margin: 1rem 0;
    margin-right: 2rem;
    margin-left: 1rem;
    font-size: 2rem;
    img {
      object-fit: contain;
    }
  }
  input {
    letter-spacing: 0.14px;
    // color: #000;
    color: #000;
    font-size: 16px;
    // font-weight: 500;
    border: 0 !important;
    outline: 0 !important;
    border-radius: 12px;
    text-align: left;
    &.PhoneInputInput {
      direction: ltr !important;
    }
  }
  .PhoneInput input {
    height: 100%;
    padding: 0;
    border: 0 !important;
    outline: 0 !important;
  }
}

.ant-modal-confirm-btns {
  .ant-btn.ant-btn-default {
    color: #fff;
  }
}

.form-inputs-wrapper {
  .form-grid-holder {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  .ant-form-item-control-input-content {
    text-align: right;
    input,
    textarea {
      text-align: right;
    }
  }
}

.terms-condition-wrapper {
  h2 {
    text-align: right;
  }
}
.text-elp {
  display: -webkit-box;
  // max-width: 300px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: auto;
}

.ant-switch-checked {
  background-color: #612166;
}
.col-name-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  .ant-rate {
    zoom: 0.77;
    .ant-rate-star-zero {
      display: none;
    }
  }
}

.ant-input[disabled],
.PhoneInput--disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
  cursor: not-allowed !important;
  opacity: 1;
  .PhoneInputCountry {
    background-color: transparent !important;
    cursor: not-allowed !important;
  }
}

.forget-pass-holder {
}

.forget-link {
  display: block;
  color: #612166 !important;
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
  text-align: center;
  margin-top: 26px;
}

.forget-form {
  .ant-form-item-label {
    text-align: left;
  }
  .PhoneInput input {
    text-align: left;
  }
  .PhoneInputCountry {
    padding-right: 0;
  }
  .forget-pass-holder {
    button {
      width: 100%;
      color: #fff !important;
    }
  }
}
.full-width {
  grid-column: 1/4;
}
.resend-link-holder {
  color: #612166;
  text-decoration: underline;
  font-size: 16px;
  cursor: pointer;
  width: fit-content;
}

.btn-action-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  .ant-btn.ant-btn-ghost.ant-btn-lg {
    background-color: transparent !important;
    color: #612166 !important;
    border-color: #612166 !important;
  }
}

.model-form-wrapper {
  .ant-modal-body {
    padding: 50px;
  }
  .ant-modal-close-x {
    color: red;
  }
}

.lang-holder {
  margin: 0 2vw;
  p {
    cursor: pointer;
    font-size: 14px;
    color: #000;
    font-weight: 500;
  }
}

.ant-pagination-options {
  display: none;
}
div.rdw-editor-toolbar {
  // margin-bottom: 0;
}
.rdw-editor-main {
  border: solid 1px #dbdbdb;
  min-height: 250px;
  background-color: #fff;
  .DraftEditor-editorContainer {
    line-height: 1.9;
    font-size: 16px;
  }
}
.rete-desc {
  font-size: 16px;
  font-weight: 500;
  color: #171717;
}

.no-wrap {
  white-space: nowrap;
}

.quill-content-holder {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background-color: #fff;
  .ql-snow {
    border: 0;
    &.ql-toolbar {
      border: 0;
      border-bottom: 1px solid #d9d9d9;
      @media only screen and (max-width: 992px) {
        flex-wrap: wrap;
      }
    }
    &.ql-container {
      border: 0;
      height: 140px;
      .ql-editor {
        padding-bottom: 30px;
        height: 100%;
        &:lang(ar) {
          text-align: right;
        }
        * {
          line-height: 1.6;
        }
        &.ql-blank {
          font-size: 16px;
          color: #8d8d8d;
          font-style: normal;
          p {
            font-style: normal;
          }
        }
      }
    }
  }
  &.quill-content-input-holder {
    .ql-snow.ql-container {
      height: auto;
      max-height: initial;
      min-height: initial;
      .ql-editor {
        height: auto;
        max-height: initial;
        min-height: initial;
      }
    }
  }
  &.rtl-input {
    .ql-snow.ql-container {
      .ql-editor {
        text-align: right;
        height: auto;
        max-height: 60vh;
        min-height: 300px;
        ol,
        ul {
          padding-left: 0;
          padding-right: 1.5em;
        }
        ol li:not(.ql-direction-rtl),
        ul li:not(.ql-direction-rtl) {
          padding-left: 0;
          padding-right: 1.5em;
          &::before {
            margin-right: -1.5em;
            margin-left: 0.3em;
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }
  }
  &.rtl-input-title {
    .ql-snow.ql-container {
      .ql-editor {
        text-align: right;
        height: auto;
        max-height: 10vh;
        min-height: 50px;
        ol,
        ul {
          padding-left: 0;
          padding-right: 1.5em;
        }
        ol li:not(.ql-direction-rtl),
        ul li:not(.ql-direction-rtl) {
          padding-left: 0;
          padding-right: 1.5em;
          &::before {
            margin-right: -1.5em;
            margin-left: 0.3em;
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }
  }
}

.quill-content-holder {
  overflow: auto;
}
.quill-content-holder .ql-snow.ql-container {
  height: auto;
  max-height: 60vh;
  min-height: 300px;
  .ql-editor {
    height: auto;
    max-height: 60vh;
    min-height: 300px;
  }
}

.quill-content-holder .ql-snow.ql-container .ql-editor * {
  direction: ltr !important;
}
.quill-content-holder.rtl-input .ql-snow.ql-container .ql-editor * {
  direction: rtl !important;
}
